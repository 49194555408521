<!-- DuplicatedTag.vue -->
<template>
  <v-tooltip right color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-if="isDuplicated" color="red" small v-bind="attrs" v-on="on">
        mdi-alert-circle
      </v-icon>
    </template>
    <span>Coincidencia</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    originalContactLead: {
      type: Object,
      required: true
    },
    compareContactLed: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    }
  },
  computed: {
    isDuplicated() {
      if (!this.originalContactLead || !this.compareContactLed) {
        return false;
      }
      if (
        !this.originalContactLead.hasOwnProperty(this.field) ||
        !this.compareContactLed.hasOwnProperty(this.field)
      ) {
        return false;
      }
      if (
        this.originalContactLead[this.field] === "" ||
        this.compareContactLed[this.field] === ""
      ) {
        return false;
      }
      if (this.field === "name") {
        const set1 = new Set(
          this.normalizeWord(this.originalContactLead.name).split(" ")
        );
        const set2 = new Set(
          this.normalizeWord(this.compareContactLed.name).split(" ")
        );

        return (
          [...set1].every(word => set2.has(word)) &&
          [...set2].every(word => set1.has(word))
        );
      }

      if (this.field === "phone") {
        const digitsCut = this.source !== "61659bc5edfca2b7e9ea7e22" ? 6 : 4;
        const originalPhone = this.originalContactLead[this.field].toString();
        const comparePhone = this.compareContactLed[this.field].toString();
        const originalLastDigits = originalPhone.slice(-digitsCut);
        const compareLastDigits = comparePhone.slice(-digitsCut);
        return originalLastDigits === compareLastDigits;
      }
      // compare field with regex
      if (this.field === "email") {
        const regex = new RegExp(this.originalContactLead[this.field], "i");
        return regex.test(this.compareContactLed[this.field]);
      }
      return (
        this.originalContactLead[this.field] ===
        this.compareContactLed[this.field]
      );
    }
  },
  methods: {
    normalizeWord(input) {
      return input
        .toLowerCase()
        .replace(/[áàâä]/g, "a")
        .replace(/[éèêë]/g, "e")
        .replace(/[íìîï]/g, "i")
        .replace(/[óòôö]/g, "o")
        .replace(/[úùûü]/g, "u");
    }
  }
};
</script>
