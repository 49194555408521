<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      @click:outside="setDialog(false)"
      @keydown.esc="setDialog(false)"
      scrollable
      :persistent="innerLoading != false"
      max-width="95vw"
    >
      <v-row class="ma-0 pa-0">
        <v-col cols="8" class="pa-0 ma-0">
          <v-card rounded="0" height="100%">
            <v-toolbar
              :dark="leadPhase !== 'finished'"
              :ligth="leadPhase !== 'finished'"
              dense
              class="white--text"
              flat
              :color="colorToolBar"
              :class="
                `lead-phase-${leadPhase} lead-tracking-phase-${leadTrackingPhase} lead-operation-phase-${leadOperationPhase}`
              "
            >
              <v-toolbar-title class="font-weight-regular my-4"
                >Detalle del Lead
                <small v-if="isMultiOportunity"> (Multi-Oportunidad )</small>
                <!-- En caso de que sea el original de multi -->
                <small
                  v-else-if="
                    lead.contact_lead?.customer && lead.phase == 'finished'
                  "
                ></small>
                <small v-if="leadTrackingPhase === 'unassigned'">
                  (Sin asignar)</small
                >
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <lead-detail-menu-actions :page="page" />
            </v-toolbar>
            <v-card
              style="cursor: pointer"
              elevation="0"
              rounded="0"
              @click="verLeadDuplicados()"
              v-if="this.lead.IsDuplicated"
            >
              <v-list dense color="red lighten-2" class="py-0">
                <v-list-item dense>
                  <v-list-item-avatar class="my-0 mr-0">
                    <v-icon dense color="white">
                      mdi-information-outline
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-info-duplicado">
                      Este Lead está duplicado

                      <small v-if="role === 'admin'"
                        >(Haz clic para más información)</small
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card
              v-if="lead.contact_external_broker_id && !loading"
              elevation="0"
              width="100%"
              color="#FCF3CF"
              class="rounded-0"
            >
              <div style="color: #2D3846; padding:10px; font-size: 18px;">
                <div class="text-center">
                  <!--
                  <v-icon style=" padding-right:10px; font-size: 25px;">
                    mdi-account-switch
                  </v-icon>-->

                  <strong>Broker Externo</strong>
                  <div v-if="lead.external_broker">
                    <div>
                      <v-row no-gutters>
                        <v-col>
                          <v-card class="pa-2" elevation="0" color="#FCF3CF">
                            <v-icon style="font-size: 25px;">
                              mdi-account
                            </v-icon>
                            {{ lead.external_broker.name }}
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card class="pa-2" elevation="0" color="#FCF3CF">
                            <v-icon style="font-size: 25px;">
                              mdi-email
                            </v-icon>
                            {{ lead.external_broker.email }}
                          </v-card>
                        </v-col>
                        <v-col v-if="lead.external_broker.phone">
                          <v-card class="pa-2" elevation="0" color="#FCF3CF">
                            <v-icon style="font-size: 25px;">
                              mdi-phone
                            </v-icon>
                            {{ lead.external_broker.phone }}
                          </v-card>
                        </v-col>

                        <v-col v-if="lead.external_broker.real_estate_name">
                          <v-card class="pa-2" elevation="0" color="#FCF3CF">
                            <v-icon style="font-size: 25px;">
                              mdi-office-building
                            </v-icon>
                            {{ lead.external_broker.real_estate_name }}
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- 
                    <p>
                      {{ lead.external_broker.name }} |
                      {{ lead.external_broker.email }} |
                      {{ lead.external_broker.phone }} |
                      {{ lead.external_broker.real_estate_name }}
                    </p>
                    -->
                  </div>
                </div>
              </div>
            </v-card>
            <v-card-text>
              <v-container>
                <!--input-editable-confirm prependIcon="mdi-home-search" /-->
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-text-field
                        :disabled="!watchAndEdit"
                        dense
                        v-model="leadName"
                        label="Nombre"
                        aria-autocomplete="off"
                        :loading="innerLoading === 'name'"
                        @blur="sendUpdateOneField('name', leadName)"
                        class="my-3"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-account</v-icon>
                        </template>
                      </v-text-field>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="role !== 'developer'">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-text-field
                        :disabled="!watchAndEdit"
                        dense
                        v-model="contactLeadEmail"
                        label="Correo"
                        aria-autocomplete="off"
                        :loading="innerLoading === 'contact_lead_email'"
                        @blur="
                          sendUpdateOneField(
                            'contact_lead_email',
                            contactLeadEmail
                          )
                        "
                        class="my-3"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-email</v-icon>
                        </template>
                      </v-text-field>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="role !== 'developer'">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-text-field
                        :disabled="!watchAndEdit"
                        dense
                        v-model="contactLeadPhone"
                        label="Teléfono"
                        aria-autocomplete="off"
                        :loading="innerLoading === 'contact_lead_phone'"
                        @blur="
                          sendUpdateOneField(
                            'contact_lead_phone',
                            formatPhone(contactLeadPhone)
                          )
                        "
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-phone</v-icon>
                        </template>
                      </v-text-field>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-text-field
                        :disabled="!watchAndEdit"
                        dense
                        v-model="contactLeadLocation"
                        label="Ubicación"
                        aria-autocomplete="off"
                        :loading="innerLoading === 'contact_lead_location'"
                        @blur="
                          sendUpdateOneField(
                            'contact_lead_location',
                            contactLeadLocation
                          )
                        "
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-map-marker</v-icon>
                        </template>
                      </v-text-field>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <div v-if="realEstateListAssigned">
                        <div
                          v-if="getTotalProperties(realEstateListAssigned) > 0"
                        >
                          <v-menu
                            bottom
                            :offset-y="true"
                            max-height="300"
                            :class="showMenuSelect ? 'menu-search' : ''"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :disabled="!watchAndEdit"
                                dense
                                v-bind="attrs"
                                v-on="on"
                                :loading="innerLoading === 'zones'"
                                label="Interesado en:"
                                hint="Ciudad, desarrollos, zona, etc."
                                @input="
                                  filterRealEstateAssing(realEstateListAssigned)
                                "
                                @blur="
                                  sendUpdateOneField(
                                    'zones',
                                    leadInterestedZones
                                  )
                                "
                                @click="
                                  tmpRealEstateListAssigned = realEstateListAssigned
                                "
                                v-model="leadInterestedZones"
                                aria-autocomplete="off"
                              >
                                <template v-slot:prepend>
                                  <v-icon>mdi-magnify</v-icon>
                                </template></v-text-field
                              >
                            </template>

                            <v-list>
                              <v-list-item
                                v-if="tmpRealEstateListAssigned.length <= 0"
                              >
                                <v-btn block text>
                                  Sin coincidencias
                                  <v-spacer></v-spacer>
                                </v-btn>
                              </v-list-item>
                              <v-list-item
                                v-else
                                v-for="(item,
                                index) in tmpRealEstateListAssigned"
                                :key="index"
                              >
                                <v-btn
                                  block
                                  text
                                  @click="
                                    selectedInterest(item),
                                      sendUpdateOneField(
                                        'zones',
                                        leadInterestedZones
                                      )
                                  "
                                >
                                  {{ item.name }}
                                  <v-spacer></v-spacer>
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div
                          v-else-if="
                            getTotalDevelops(realEstateListAssigned) > 0
                          "
                        >
                          <v-menu
                            bottom
                            :offset-y="true"
                            max-height="300"
                            :class="showMenuSelect ? 'menu-search' : ''"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :disabled="!watchAndEdit"
                                dense
                                v-bind="attrs"
                                v-on="on"
                                label="Interesado en:"
                                :loading="innerLoading === 'zones'"
                                hint="Ciudad, desarrollos, zona, etc."
                                @input="
                                  filterRealEstateAssing(realEstateListAssigned)
                                "
                                @blur="
                                  sendUpdateOneField(
                                    'zones',
                                    leadInterestedZones
                                  )
                                "
                                @click="
                                  tmpRealEstateListAssigned = realEstateListAssigned
                                "
                                v-model="leadInterestedZones"
                                aria-autocomplete="off"
                              ></v-text-field>
                            </template>

                            <v-list>
                              <v-list-item
                                v-if="tmpRealEstateListAssigned.length <= 0"
                              >
                                <v-btn block text>
                                  Sin coincidencias
                                  <v-spacer></v-spacer>
                                </v-btn>
                              </v-list-item>
                              <v-list-item
                                v-else
                                v-for="(item,
                                index) in tmpRealEstateListAssigned"
                                :key="index"
                              >
                                <v-btn
                                  block
                                  text
                                  @click="
                                    selectedInterest(item),
                                      sendUpdateOneField(
                                        'zones',
                                        leadInterestedZones
                                      )
                                  "
                                >
                                  {{ item.name }}
                                  <v-spacer></v-spacer>
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div v-else>
                          <v-text-field
                            :disabled="!watchAndEdit"
                            dense
                            aria-autocomplete="off"
                            v-model="leadInterestedZones"
                            label="Interesado en"
                            :loading="innerLoading === 'zones'"
                            @blur="
                              sendUpdateOneField('zones', leadInterestedZones)
                            "
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi-home-search</v-icon>
                            </template>
                          </v-text-field>
                        </div>
                      </div>
                      <div v-else>
                        <v-text-field
                          :disabled="!watchAndEdit"
                          dense
                          aria-autocomplete="off"
                          v-model="leadInterestedZones"
                          label="Interesado en"
                          :loading="innerLoading === 'zones'"
                          @blur="
                            sendUpdateOneField('zones', leadInterestedZones)
                          "
                        >
                          <template v-slot:prepend>
                            <v-icon>mdi-home-search</v-icon>
                          </template>
                        </v-text-field>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="6" class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-2">
                      <v-row justify="center" align="center" class="">
                        <v-col cols="6">
                          <v-skeleton-loader
                            :loading="loading"
                            height="50"
                            type="list-item"
                          >
                            <v-row justify="start" align="start">
                              <v-col cols="2">
                                <div
                                  class="pa-2 blue lighten-5 rounded-circle d-inline-block"
                                >
                                  <v-img
                                    v-if="
                                      contactSource &&
                                        contactSource.media &&
                                        contactSource.media.src
                                    "
                                    contain
                                    :src="contactSource.media.src"
                                    width="20px"
                                    height="20px"
                                  >
                                  </v-img>
                                </div>
                              </v-col>
                              <v-col cols="10">
                                <div class="mx-2">
                                  <v-select
                                    :disabled="
                                      userRole === 'developer' ||
                                        userRole === 'marketing' ||
                                        userRole === 'marketingv2'
                                    "
                                    v-model="contactSource"
                                    :items="sourcesList"
                                    item-text="name"
                                    return-object
                                  >
                                    <template v-slot:label>
                                      ¿Dónde se enteró?

                                      <!--  <v-tooltip
                                        max-width="300px"
                                        color="black"
                                        bottom
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            style="font-size: 22px; cursor: pointer;"
                                          >
                                            mdi-help-circle
                                          </v-icon>
                                        </template>

                                        <v-row class="pa-4">
                                          <v-col cols="12" class="ma-0 pa-0">
                                            ¿Dónde nos encontró?
                                          </v-col>
                                        </v-row>
                                      </v-tooltip> -->
                                    </template>
                                    <template v-slot:item="{ item, on }">
                                      <v-list-item
                                        v-on="on"
                                        @click="updateContactSource(item)"
                                      >
                                        <v-list-item-avatar>
                                          <div
                                            class="pa-2 blue lighten-5 rounded-circle d-inline-block"
                                          >
                                            <v-img
                                              v-if="item && item.media"
                                              contain
                                              :src="item.media.src"
                                              width="20px"
                                              height="20px"
                                            >
                                            </v-img>
                                          </div>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            <v-row no-gutters align="center">
                                              <div>{{ item.name }}</div>
                                            </v-row>
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-select>
                                </div>
                              </v-col>
                            </v-row>
                          </v-skeleton-loader>
                        </v-col>
                        <v-col cols="6">
                          <v-skeleton-loader
                            :loading="loading"
                            height="50"
                            type="list-item"
                          >
                            <v-row justify="start" align="start">
                              <v-col cols="2">
                                <div
                                  class="pa-2 blue lighten-5 rounded-circle d-inline-block"
                                >
                                  <v-img
                                    v-if="
                                      contactMedium &&
                                        contactMedium.media &&
                                        contactMedium.media.src
                                    "
                                    contain
                                    :src="contactMedium.media.src"
                                    width="20px"
                                    height="20px"
                                  >
                                  </v-img>
                                </div>
                              </v-col>
                              <v-col cols="10">
                                <div class="mx-2">
                                  <v-select
                                    :disabled="
                                      userRole === 'developer' ||
                                        userRole === 'marketing' ||
                                        userRole === 'marketingv2'
                                    "
                                    v-model="contactMedium"
                                    :items="mediumsList"
                                    item-text="name"
                                    return-object
                                    label="Medio"
                                  >
                                    <template v-slot:label>
                                      ¿Cómo nos contactó?

                                      <!--      <v-tooltip
                                        max-width="300px"
                                        color="black"
                                        bottom
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            style="font-size: 22px; cursor: pointer;"
                                          >
                                            mdi-help-circle
                                          </v-icon>
                                        </template>

                                        <v-row class="pa-4">
                                          <v-col cols="12" class="ma-0 pa-0">
                                            ¿Cómo nos contactó?
                                          </v-col>
                                        </v-row>
                                      </v-tooltip> -->
                                    </template>
                                    <template v-slot:item="{ item, on }">
                                      <v-list-item
                                        v-on="on"
                                        @click="updateContactMedium(item)"
                                      >
                                        <v-list-item-avatar>
                                          <div
                                            class="pa-2 blue lighten-5 rounded-circle d-inline-block"
                                          >
                                            <v-img
                                              v-if="item && item.media"
                                              contain
                                              :src="item.media.src"
                                              width="20px"
                                              height="20px"
                                            >
                                            </v-img>
                                          </div>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            <v-row no-gutters align="center">
                                              <div>{{ item.name }}</div>
                                            </v-row>
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-select>
                                </div>
                              </v-col>
                            </v-row>
                          </v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-text-field
                        :disabled="!watchAndEdit"
                        dense
                        type="number"
                        aria-autocomplete="off"
                        v-model="leadBudget"
                        label="Presupuesto"
                        :loading="innerLoading === 'budget'"
                        @blur="sendUpdateOneField('budget', leadBudget)"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-currency-usd</v-icon>
                        </template>
                      </v-text-field>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-skeleton-loader :loading="loading" type="list-item">
                      <v-select
                        :disabled="!watchAndEdit"
                        v-model="leadCurrency"
                        :items="currency"
                        label="Moneda"
                        @input="leadCurrency = $event"
                        @change="leadCurrency = $event"
                        @blur="sendUpdateOneField('currency', leadCurrency)"
                        hide-details
                        prepend-icon="mdi-map"
                        single-line
                      ></v-select>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="10" class="pb-5 lead-input-field">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item-three-line"
                    >
                      <v-col cols="12">
                        <v-textarea
                          :disabled="!watchAndEdit"
                          dense
                          label="Observaciones"
                          rows="1"
                          auto-grow
                          aria-autocomplete="off"
                          v-model="leadObservations"
                          :loading="innerLoading === 'observations_detail'"
                          @blur="
                            () => {
                              sendUpdateOneField(
                                'observations_detail',
                                leadObservations
                              );
                            }
                          "
                        >
                          <template v-slot:prepend>
                            <v-icon>mdi-text</v-icon>
                          </template>
                        </v-textarea>
                      </v-col>
                      <v-row>
                        <v-col cols="6" v-if="lead.observations_detail?.link">
                          <v-text-field
                            :disabled="!watchAndEdit"
                            dense
                            filled
                            :value="lead.observations_detail?.link"
                            aria-autocomplete="off"
                            label="Enlace"
                            :loading="innerLoading === 'observations_detail'"
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi-link-variant</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          v-if="lead.observations_detail?.contact_link"
                        >
                          <v-text-field
                            :disabled="!watchAndEdit"
                            dense
                            filled
                            :value="lead.observations_detail?.contact_link"
                            aria-autocomplete="off"
                            label="Enlace de Contacto"
                            :loading="innerLoading === 'observations_detail'"
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi-link-variant</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <div class="text-center">
                      <v-skeleton-loader
                        :loading="loading"
                        height="50"
                        type="avatar"
                      >
                        <v-menu
                          :disabled="!watchAndEdit"
                          class="py-8"
                          v-model="showProfileSelect"
                          absolute
                          :close-on-content-click="false"
                          :nudge-width="84"
                          :nudge-right="10"
                          :nudge-top="60"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              class="mt-3"
                              contain
                              height="48"
                              v-bind="attrs"
                              v-on="on"
                              :src="
                                !leadProfile && !hasEnterUpdateProfile
                                  ? emptyProfileImage
                                  : profileItems[slider].srcImage
                              "
                            ></v-img>
                          </template>
                          <!--  :thumb-color="profileItems[slider].color" -->
                          <v-slider
                            dense
                            vertical
                            v-model="slider"
                            thumb-size="24"
                            thumb-label="always"
                            min="0"
                            max="3"
                            @focus="hasEnterUpdateProfile = true"
                            @change="
                              () => {
                                sendUpdateOneField(
                                  'profile',
                                  profileItems[slider].value
                                );
                                showProfileSelect = false;
                              }
                            "
                          >
                            <template v-slot:thumb-label="{ value }">
                              <v-img
                                contain
                                :height="48"
                                :src="profileItems[value].srcImage"
                              />
                            </template>
                          </v-slider>
                        </v-menu>
                      </v-skeleton-loader>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="role === 'admin'">
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <select-contact-broker
                        :page="'leadDetail'"
                        @getOption="() => {}"
                      />
                    </v-skeleton-loader>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="
                      leadSlected !== 'unassigned_tracking' &&
                        leadPhaseStatus !== true
                    "
                  >
                    <v-skeleton-loader
                      v-if="lead.phase != 'discarded'"
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-select
                        :disabled="!watchAndEdit"
                        v-if="lead.phase != 'discarded'"
                        ref="phaseLead"
                        label="Selecciona una fase"
                        item-disabled="disable"
                        :items="
                          getPhasesToShow(
                            phasesLeads,
                            realEstateGroup
                              ? realEstateGroup.real_estate_phase
                              : []
                          )
                        "
                        v-model="leadSlected"
                        @change="sendUpdateLeadPhase(leadSlected)"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-filter</v-icon>
                        </template>
                      </v-select>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-skeleton-loader
                      v-if="lead.contact"
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-select
                        :value="lead.contact.language"
                        label="Idioma"
                        :items="['Español', 'Inglés']"
                        @change="updateLanguage"
                      >
                        <template v-slot:prepend>
                          <v-icon>mdi-translate</v-icon>
                        </template>
                      </v-select>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    v-if="leadSlected !== 'unassigned_tracking'"
                  >
                    <v-skeleton-loader
                      :loading="loading"
                      height="50"
                      type="list-item"
                    >
                      <v-card width="200px" elevation="0">
                        <v-switch
                          :disabled="!watchAndEdit"
                          color="success"
                          v-model="leadPhaseStatus"
                          @change="
                            toggleEndPhase(leadPhase, $event !== null, $event)
                          "
                        >
                          <template v-slot:label>
                            <div>
                              {{
                                leadPhaseStatus === true
                                  ? "Comisión cobrada"
                                  : "Comisión cobrada"
                              }}
                            </div>
                          </template>
                        </v-switch>
                      </v-card>
                    </v-skeleton-loader>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <v-skeleton-loader
                          :loading="loading"
                          height="30"
                          type="list-item"
                        >
                          <label class="font-weight-bold"
                            >Fecha de registro:</label
                          >
                          {{ formatDate(lead.created_at) }}
                        </v-skeleton-loader>

                        <v-skeleton-loader
                          :loading="loading"
                          height="30"
                          type="list-item"
                        >
                          <label class="font-weight-bold">Origen:</label>
                          {{ lead.zones }}
                        </v-skeleton-loader>
                      </v-col>
                      <v-col cols="6">
                        <v-skeleton-loader
                          :loading="loading"
                          height="30"
                          type="list-item"
                        >
                          <div>
                            <label class="font-weight-bold">Fuente:</label>

                            <img
                              class="mx-2"
                              :src="lead.contact?.source.media.src"
                              width="20px"
                              height="20px"
                            />
                            {{ lead.contact?.source.name }}
                          </div>
                          <div v-if="lead.miscellaneous">
                            <div v-if="lead.miscellaneous.marketing">
                              <div v-if="lead.miscellaneous.marketing.campaign">
                                <label class="font-weight-bold">Campaña:</label>
                                {{ lead.miscellaneous.marketing.campaign }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.adSet">
                                <label class="font-weight-bold"
                                  >Segmentación:</label
                                >
                                {{ lead.miscellaneous.marketing.adSet }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.ad">
                                <label class="font-weight-bold">Anuncio:</label>
                                {{ lead.miscellaneous.marketing.ad }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.form">
                                <label class="font-weight-bold"
                                  >Formulario:</label
                                >
                                {{ lead.miscellaneous.marketing.form }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.cost">
                                <label class="font-weight-bold">Costo:</label>
                                {{ lead.miscellaneous.marketing.cost }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.sales">
                                <label class="font-weight-bold">Ventas:</label>
                                {{ lead.miscellaneous.marketing.sales }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.revenue">
                                <label class="font-weight-bold"
                                  >Ingresos:</label
                                >
                                {{ lead.miscellaneous.marketing.revenue }}
                              </div>

                              <div v-if="lead.miscellaneous.marketing.utm_term">
                                <label class="font-weight-bold"
                                  >Palabra Clave:</label
                                >
                                {{ lead.miscellaneous.marketing.utm_term }}
                              </div>
                              <div
                                v-if="lead.miscellaneous.marketing.utm_content"
                              >
                                <label class="font-weight-bold">Anuncio:</label>
                                {{ lead.miscellaneous.marketing.utm_content }}
                              </div>
                            </div>
                          </div>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      v-if="lead.phase == 'discarded'"
                      class="grey lighten-4 rounded text-center"
                    >
                      <v-skeleton-loader
                        :loading="loading"
                        height="50"
                        type="list-item"
                      >
                        <label class="font-weight-bold"
                          >Motivo de descarte:</label
                        >
                        {{
                          lead.discard_observations
                            ? lead.discard_observations
                            : "No sé específico el motivo de descarte."
                        }}
                      </v-skeleton-loader>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>

              <v-container>
                <v-skeleton-loader :loading="loading" height="94" type="card">
                  <lead-comments
                    :lead="lead"
                    :leadId="lead._id"
                    :lead-phase="lead.phase"
                    :lead-tracking-phase="lead.tracking_phase"
                    :lead-operation-phase="lead.operation_phase"
                    :comments="lead.comments"
                    :adminsComments="lead.comments_contact_admin"
                    :brokersComments="lead.comments_contact_broker"
                    :developersComments="lead.comments_contact_developer"
                    :srcBrokerImage="lead.internal_broker | brokerImageFormat"
                    :brokerName="brokerName"
                  />
                </v-skeleton-loader>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Aqui comienzan la parte de el perfilApp -->
        <!-- Aqui comienzan la parte de el perfilApp -->
        <!-- Aqui comienzan la parte de el perfilApp -->
        <!-- Aqui comienzan la parte de el perfilApp -->
        <!-- Aqui comienzan la parte de el perfilApp -->
        <v-col cols="4" class="pa-0 ma-0">
          <v-card height="100%" color="#fbf1fd" rounded="0">
            <v-toolbar
              :dark="leadPhase !== 'finished'"
              :ligth="leadPhase !== 'finished'"
              dense
              class="white--text"
              flat
              :class="
                `lead-phase-${leadPhase} lead-tracking-phase-${leadTrackingPhase} lead-operation-phase-${leadOperationPhase}`
              "
            >
              <v-toolbar-title class="font-weight-regular my-4"
                >Perfilación</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <!-- <lead-detail-menu-actions /> -->
              <v-btn icon @click="setDialog(false)" id="close-lead-modal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-container>
              <LeadPerfilApp />
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog
      v-model="modalDuplicados"
      @click:outside="modalDuplicados = false"
      @keydown.esc="modalDuplicados = true"
      scrollable
      :persistent="innerLoading != false"
      max-width="65vw"
    >
      <v-card max-height="65vh">
        <v-toolbar dark dense class="white--text" flat>
          <v-toolbar-title class="font-weight-regular my-4"
            >Leads duplicados</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="modalDuplicados = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="px-0 pb-0" min-height="325">
          <v-list
            v-if="
              this.leadsDuplicados &&
                this.leadsDuplicados.Lead &&
                this.leadsDuplicados.Lead.length > 1
            "
          >
            <v-list-item-group>
              <div
                v-for="(item, index) in this.leadsDuplicados.Lead"
                :key="item._id"
              >
                <div v-if="item._id !== lead._id">
                  <v-list-item>
                    <template>
                      <v-list-item-avatar size="25">
                        <v-img
                          :src="getSourceLeadDuplicado(item.contact?.source)"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle class="caption grey--text"
                          >Creado:
                          {{
                            formatDate(item.created_at)
                          }}</v-list-item-subtitle
                        >
                        <v-list-item-title>
                          {{ item.contact_lead.name }}

                          <ContactDataDuplicateVue
                            :originalContactLead="lead.contact_lead"
                            :compareContactLed="item.contact_lead"
                            field="name"
                            :source="getSourceId(lead.contact?.source)"
                          />
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          Asignado a:
                          {{
                            getBrokerAsignedLeadDuplicado(
                              item.contact_broker_id
                            )
                          }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          Telefono: {{ item.contact_lead.phone }}
                          <ContactDataDuplicateVue
                            :originalContactLead="lead.contact_lead"
                            :compareContactLed="item.contact_lead"
                            field="phone"
                            :source="getSourceId(lead.contact?.source)"
                          />
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          Correo:
                          {{ item.contact_lead.email }}
                          <ContactDataDuplicateVue
                            :originalContactLead="lead.contact_lead"
                            :compareContactLed="item.contact_lead"
                            field="email"
                            :source="getSourceId(lead.contact?.source)"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <lead-postpone-action
                          :postponed="
                            item.postponed ? item.postponed.date_at : ''
                          "
                          :leadId="item._id"
                          :lead="item"
                        />
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
                </div>
              </div>
            </v-list-item-group>
          </v-list>
          <v-container v-else>
            <v-img
              contain
              max-height="300"
              src="../../../public/img/undraw_No_data_re_kwbl.png"
            >
            </v-img>
            <v-row justify="center" align="center">
              <v-col cols="12" justify="center" align="center">
                <div class="title">
                  Vaya, parece que no hay leads duplicados por mostrar.
                </div>
              </v-col>
              <v-col cols="12" justify="center" align="center">
                <div class="subtitle-2 grey--text">
                  No se encontraron leads duplicados automaticamente, intenta
                  usando el buscador.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modalDuplicados = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import ContactDataDuplicateVue from "./components/ContactDataDuplicate.vue";
moment.locale("es");
export default {
  components: {
    // InputEditableConfirm: () => import("@/components/layout/inputs/InputEditableConfirm.vue"),
    LeadComments: () => import("@/components/lead/LeadComments.vue"),
    LeadPerfilApp: () => import("@/components/lead/LeadPerfilApp.vue"),
    LeadDetailMenuActions: () =>
      import("@/components/lead/LeadDetailMenuActions.vue"),
    SelectContactBroker: () =>
      import("@/components/contact-broker/SelectContactBroker.vue"),
    LeadPostponeAction: () => import("@/components/lead/LeadPostpone.vue"),
    ContactDataDuplicateVue
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    page: {
      type: String,
      default: ""
    }
  },
  filters: {
    brokerImageFormat(broker) {
      if (!(broker && broker.name)) return "";
      let brokerSrcImg = "";
      let brokerName = "";
      if (broker.name) brokerName = broker.name;
      if (
        broker.media &&
        broker.media.featured_image &&
        broker.media.featured_image.src
      )
        brokerSrcImg = broker.media.featured_image.src;
      if (!brokerName) brokerName = "C28";
      if (!brokerSrcImg)
        brokerSrcImg =
          "https://dummyimage.com/400x200/262d3c/fff&text=" +
          brokerName.substring(0, 2);

      return brokerSrcImg;
    },
    mktFilter(mktString, param) {
      // comprobar que exista el string mkt
      if (!mktString) return " - ";
      let findParam = param;
      // solo el campo Campaña no tiene '|' al principio
      if (param !== "Campaña") findParam = `| ${param}`;
      // revisar que exista el parametro en el string mkt
      const index = mktString.indexOf(findParam);
      if (index < 0) return " - ";
      let startPos = index + param.length + 2;
      if (param !== "Campaña") startPos += 2; // aumentar otros 2 por separador '|'
      // encontrar el siguiente delimitador '|'
      const indexDelimiter =
        mktString.indexOf("|", startPos) > 0
          ? mktString.indexOf("|", startPos)
          : mktString.length;
      return mktString.substring(startPos, indexDelimiter).trim();
    }
  },
  data() {
    return {
      slider: 0,
      role: localStorage.getItem("user_role"),
      showMenuSelect: false,
      // bandera que indica si abrió el panel para editar el termometro
      hasEnterUpdateProfile: false,
      publicPath: process.env.BASE_URL,
      innerLoading: false,
      userRole: localStorage.getItem("user_role") || "",
      showProfileSelect: false,
      emptyProfileImage: `${process.env.BASE_URL}vacio.png`,
      contactSelectedId: "",
      completeLeadSurvey: null,
      suggestedRealEstate: [],
      sourcesList: [],
      contactSource: null,
      mediumsList: [],
      contactMedium: null,
      profileItems: [
        {
          value: "D",
          srcImage: `${process.env.BASE_URL}50azul.png`,
          color: "blue"
        },

        {
          value: "C",
          srcImage: `${process.env.BASE_URL}50amarillo.png`,
          color: "yellow"
        },
        {
          value: "B",
          srcImage: `${process.env.BASE_URL}50naranja.png`,
          color: "orange"
        },

        {
          value: "A",
          srcImage: `${process.env.BASE_URL}50rojo.png`,
          color: "red"
        }
      ],
      phasesLeads: [
        {
          text: "Por asignar",
          val: "unassigned",
          value: "unassigned_tracking"
        },
        {
          text: "Asignado",
          val: "assigned",
          value: "assigned_tracking"
        },
        {
          text: "Por perfilar",
          val: "to-contact",
          value: "to-contact_tracking"
        },
        {
          text: "Búsqueda",
          val: "searching",
          value: "searching_tracking"
        },
        {
          text: "Seguimiento",
          val: "tracking",
          value: "tracking_tracking"
        },
        {
          text: "Recorrido",
          val: "scheduled-tour",
          value: "scheduled-tour_tracking"
        },
        {
          text: "Seguimiento post-recorrido",
          val: "finished-tour",
          value: "finished-tour_tracking"
        },
        {
          text: "Ofertando",
          val: "offer",
          value: "offer_tracking"
        },
        {
          text: "Fecha de Apartado",
          val: "downpayment",
          value: "downpayment_tracking"
        },
        {
          text: "Promesa",
          val: "contract",
          value: "contract_operation"
        },
        {
          text: "Cierre",
          val: "closing-trade",
          value: "closing-trade_operation"
        }
        // {
        //   text: "Cerrado",
        //   value: "finished_operation"
        // },
      ],
      currency: ["MXN", "USD"],
      typeSelected: null,
      paySelected: null,
      objectiveSelected: null,
      searchingTime: null,
      optionsSaw: null,
      workingWith: null,
      deadlineTime: null,
      leadCurrency: null,
      tmpRealEstateListAssigned: [],
      phone: "",
      text: "Lorem ipsum dolor sit amet",
      modalDuplicados: false,
      items: [
        {
          action: "15 min",
          headline: "Brunch this weekend?",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: "Ali Connors"
        },
        {
          action: "2 hr",
          headline: "Summer BBQ",
          subtitle: `Wish I could come, but I'm out of town this weekend.`,
          title: "me, Scrott, Jennifer"
        },
        {
          action: "6 hr",
          headline: "Oui oui",
          subtitle: "Do you have Paris recommendations? Have you ever been?",
          title: "Sandra Adams"
        },
        {
          action: "12 hr",
          headline: "Birthday gift",
          subtitle:
            "Have any ideas about what we should get Heidi for her birthday?",
          title: "Trevor Hansen"
        },
        {
          action: "18hr",
          headline: "Recipe to try",
          subtitle:
            "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
          title: "Britta Holt"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      realEstateAssigned: state =>
        state.realEstateDevelopments.realEstateAssign,
      loadingRealEstate: state =>
        state.realEstateDevelopments.loadingRealEstateAssign,
      loading: state => state.leads.loading,
      lead: state => state.leads.actualItem,
      leadSecondary: state => state.leads.secondaryItem,
      leadsDuplicados: state => state.leads.leadsDuplicados,
      realEstateListAssigned: state =>
        state.realEstateDevelopments.realEstateAssign,
      realEstateGroupLoading: state =>
        state.realEstateGroup.realEstateGroupLoading,
      realEstateGroup: state => state.realEstateGroup.realEstateGroup,
      filters: state => state.leads.filters
    }),
    colorToolBar() {
      if (this.lead.tracking_phase == "unassigned") return "#600202";
      if (this.lead.phase == "finished") return "";
      if (this.isMultiOportunity) return "";
      return "primary";
    },
    isMultiOportunity() {
      if (this.lead.hasOwnProperty("multi_sales_opportunities"))
        return this.lead.multi_sales_opportunities.length != 0;
      return false;
    },
    watchAndEdit() {
      if (this.role == "admin") return true;
      if (this.role == "broker") return true;
      return false;
    },
    leadName: {
      get() {
        if (this.lead && this.lead.contact_lead_name) {
          return this.lead.contact_lead_name;
        }
        return "";
      },
      set(newValue) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          name: newValue,
          contact_lead_name: newValue
        });
      }
    },
    contactLeadEmail: {
      get() {
        if (this.lead && this.lead.contact_lead_email) {
          if (this.role === "marketing" || this.role === "marketingv2")
            return "";
          return this.lead.contact_lead_email;
        }
        return "";
      },
      set(newValue) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          email: newValue,
          contact_lead_email: newValue
        });
      }
    },
    contactLeadPhone: {
      get() {
        if (this.lead && this.lead.contact_lead_phone) {
          if (this.role === "marketing" || this.role === "marketingv2")
            return "";
          return this.lead.contact_lead_phone;
        }
        return "";
      },
      set(newValue) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          phone: newValue,
          contact_lead_phone: newValue
        });
      }
    },
    contactLeadLocation: {
      get() {
        if (this.lead && this.lead.contact_lead_location)
          return this.lead.contact_lead_location;
        return "";
      },
      set(newValue) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          location: newValue,
          contact_lead_location: newValue
        });
      }
    },
    leadInterestedZones: {
      get() {
        if (this.lead && this.lead.zones) return this.lead.zones;
        return "";
      },
      set: function(newValue) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          zones: newValue
        });
      }
    },
    leadBudget: {
      get() {
        if (this.lead && this.lead.budget) return this.lead.budget;
        return "";
      },
      set: function(newValue) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          budget: newValue
        });
      }
    },
    leadObservations: {
      get() {
        if (
          this.lead &&
          this.lead.observations_detail &&
          this.lead.observations_detail.message
        ) {
          return this.lead.observations_detail.message;
        } else {
          return "";
        }
      },
      set: function(newValue) {
        this.$store.commit("leads/updateActualLead", {
          page: this.page,
          leadId: this.lead._id,
          observations_detail: {
            message: newValue,
            category:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail.category
                : "",
            interested_unit:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail.interested_unit
                : "",
            contact_link:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail?.contact_link
                : "",
            link:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail?.link
                : ""
          }
        });
      }
    },
    leadProfile: {
      get() {
        if (this.lead && this.lead.profile) return this.lead.profile;
        return "";
      },
      set: function(newValue) {
        this.$store.commit("leads/updateActualLead", {
          page: this.page,
          profile: newValue
        });
      }
    },
    leadPhase: {
      get() {
        if (this.lead && this.lead.phase) return this.lead.phase;
        return "";
      }
    },
    leadPhaseStatus: {
      get() {
        if (this.lead && this.lead.phase && this.lead.phase === "finished")
          return true;
        else return false;
      },
      set: function(newValue) {
        if (newValue) {
          this.$store.commit("leads/updateActualLead", {
            page: this.page,
            phase: "finished"
          });
        } else {
          this.$store.commit("leads/updateActualLead", {
            page: this.page,
            phase: "active"
          });
        }
      }
    },
    leadTrackingPhase: {
      get() {
        if (this.lead && this.lead.tracking_phase)
          return this.lead.tracking_phase;
        return "";
      }
    },
    leadOperationPhase: {
      get() {
        if (this.lead && this.lead.operation_phase)
          return this.lead.operation_phase;
        return "";
      }
    },
    brokerselected: {
      get() {
        if (this.lead && this.lead.internal_broker) {
          return this.lead.internal_broker._id;
        }
        if (this.lead && this.lead.internal_admin) {
          return this.lead.internal_admin._id;
        }
        return "";
      }
    },
    leadSlected: {
      get() {
        if (
          this.lead &&
          this.lead.operation_phase &&
          !this.lead.tracking_phase
        ) {
          return `${this.lead.operation_phase}_operation`;
        } else {
          return `${this.lead.tracking_phase}_tracking`;
        }
      },
      set: function(newValue) {
        if (newValue) {
          const phaseLead = newValue.split("_");
          const valuePhase = phaseLead[0];
          const phase = phaseLead[phaseLead.length - 1];
          if (phase === "tracking") {
            this.$store.commit("leads/updateActualLead", {
              leadId: this.lead._id,
              page: this.page,
              phase: "active",
              value_phase: valuePhase,
              tracking_phase: valuePhase,
              operation_phase: ""
            });
          } else {
            this.$store.commit("leads/updateActualLead", {
              leadId: this.lead._id,
              page: this.page,
              phase: "in-operation",
              value_phase: valuePhase,
              operation_phase: valuePhase,
              tracking_phase: ""
            });
          }
        }
      }
    },
    leadgetMkt: {
      get() {
        if (this.lead && this.lead.miscellaneous) {
          return this.lead.miscellaneous.mkt;
        } else {
          return false;
        }
      }
    },
    brokerName() {
      try {
        return this.lead.internal_broker.name;
      } catch {
        return "";
      }
    }
  },
  watch: {
    leadProfile(newValue) {
      let profileNumber = 0;
      switch (newValue) {
        case "A":
          profileNumber = 3;
          break;
        case "B":
          profileNumber = 2;
          break;
        case "C":
          profileNumber = 1;
          break;
        case "D":
          profileNumber = 0;
          break;
        default:
          profileNumber = 0;
          break;
      }
      this.slider = profileNumber;
    },
    loading(newVal) {
      if (!newVal) {
        this.getContactSource();
        this.getContactMedium();
        if (this.lead._id !== undefined) {
          if (this.lead.currency) {
            this.leadCurrency = this.lead.currency;
          } else {
            this.leadCurrency = "MXN";
          }
        } else {
          this.completeLeadSurvey = null;
          this.leadCurrency = "MXN";
        }
      } else {
        this.completeLeadSurvey = null;
        this.leadCurrency = "MXN";
      }
    },
    filters() {
      this.getTrafficData();
    }
  },
  mounted() {
    this.tmpRealEstateListAssigned = this.realEstateListAssigned;
  },
  created() {
    // this.viewPhasesLeads = this.phasesLeads;
    this.tmpRealEstateListAssigned = this.realEstateListAssigned;
  },
  methods: {
    ...mapActions({
      getTrafficDataStore: "getTrafficData",
      fetchUpdatelLead: "leads/fetchUpdatelLead",
      updateLeadV2: "leads/updateLeadV2",
      fetchCompleteLeadSurveySt: "leads/fetchCompleteLeadSurvey",
      nextQuestionSt: "leads/nextQuestion",
      fetchActualLead: "leads/fetchActualLead",
      fetchLeadDuplicados: "leads/fetchLeadDuplicados"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    getTrafficData() {
      for (let i = 0; i < this.filters.sources.length; i++) {
        this.sourcesList.push(this.filters.sources[i].source);
      }
      for (let i = 0; i < this.filters.mediums.length; i++) {
        this.mediumsList.push(this.filters.mediums[i].medium);
      }
    },
    getSourceLeadDuplicado(idSourcelead) {
      const source = this.filters.sources.find(
        source => source.source_id === idSourcelead
      );
      return source ? source.source.media.src : "";
    },
    getBrokerAsignedLeadDuplicado(idBrokerAsignedlead) {
      const broker = this.filters.contacts.find(
        brk => brk._id === idBrokerAsignedlead
      );
      return broker ? broker.name : "Sin asignar";
    },
    formatDate(date) {
      if (!date) return "";
      let format = "DD-MMM-YYYY h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    },
    formatDatev2(date) {
      return moment(date).format();
    },
    getPhasesToShow(list, hides) {
      let final = JSON.parse(JSON.stringify(list));
      if (hides) {
        for (let i = 0; i < list.length; i++) {
          if (!hides.includes(list[i].val)) {
            final.push(list[i]);

            // No se modifica la lista principal porque se cambiaria la lista principal
            const actualFhase = this.lead.tracking_phase;
            if (list[i].val == "unassigned" && actualFhase != "unassigned")
              final[i].disable = true;
            if (
              list[i].val == "assigned" &&
              actualFhase != "assigned" &&
              actualFhase != "unassigned"
            )
              final[i].disable = true;
            if (
              list[i].val == "to-contact" &&
              actualFhase != "to-contact" &&
              actualFhase != "assigned"
            )
              final[i].disable = true;
          }
        }
        return final;
      } else {
        return list;
      }
    },
    getBrokerselected(l) {
      if (l.internal_admin && l.internal_admin.name) {
        return l.internal_admin;
      } else if (l.internal_broker && l.internal_broker.name) {
        return l.internal_broker;
      } else {
        return { name: "Por asginar", role: "" };
      }
    },
    getContactSource() {
      if (this.lead && this.lead.contact && this.lead.contact?.source) {
        this.contactSource = this.lead.contact?.source;
      } else {
        this.contactSource = "";
      }
    },
    getContactMedium() {
      if (this.lead && this.lead.contact && this.lead.contact.medium) {
        this.contactMedium = this.lead.contact.medium;
      } else {
        this.contactMedium = "";
      }
    },
    updateContactSource(item) {
      this.$store.commit("leads/updateActualLead", {
        leadId: this.lead._id,
        contact: {
          source: item
        }
      });
      this.sendUpdateOneField("contact", {
        source: item._id
      });
    },
    async updateContactMedium(item) {
      this.$store.commit("leads/updateActualLead", {
        leadId: this.lead._id,
        contact: {
          medium: item
        }
      });
      await this.sendUpdateOneField("contact", {
        medium: item._id
      });
    },
    getTotalProperties(list) {
      var totalProperties = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i].totalProperties > 0) {
          totalProperties = totalProperties + 1;
        }
      }
      return totalProperties;
    },
    getTotalDevelops(list) {
      return list.length;
    },
    selectedInterest(item) {
      if (item) {
        this.leadInterestedZones = item.name;
      }
      this.showMenuSelect = false;
    },
    filterRealEstateAssing(list) {
      const self = this;
      self.showMenuSelect = true;
      if (self.leadInterestedZones) {
        let tmpList = list.filter(e => {
          return e.name
            .toLowerCase()
            .includes(self.leadInterestedZones.toLowerCase());
        });
        self.tmpRealEstateListAssigned = tmpList;
      } else {
        self.tmpRealEstateListAssigned = list;
      }
    },
    getRealEstateAssignList() {
      let list = [];
      for (let i = 0; i < this.realEstateAssigned.length; i++) {
        list.push(this.realEstateAssigned[i].name);
      }
      this.suggestedRealEstate = list;
    },
    filterRealEstateAssignList() {
      if (this.leadInterestedZones) {
        let filterlist = this.realEstateAssigned.filter(r => {
          return r.name
            .toLowerCase()
            .includes(this.leadInterestedZones.toLowerCase());
        });
        let list = [];
        for (let i = 0; i < filterlist.length; i++) {
          list.push(filterlist[i].name);
        }
        this.suggestedRealEstate = list;
      } else {
        this.getRealEstateAssignList();
      }
    },
    getProfileImage(profile) {
      let image = "";
      switch (profile) {
        case "A":
          image = `${this.publicPath}50rojo.png`;
          break;
        case "B":
          image = `${this.publicPath}50naranja.png`;
          break;
        case "C":
          image = `${this.publicPath}50amarillo.png`;
          break;
        case "D":
          image = `${this.publicPath}50azul.png`;
          break;
        default:
          image = "";
          break;
      }
      return image;
    },
    getHowDidContactUsIcon(howDidContactUs) {
      if (howDidContactUs === undefined) return "mdi-information-outline";
      if (!howDidContactUs) return "mdi-information-outline";
      let icon = "";
      let image = false;
      let url = "";
      switch (howDidContactUs.toLowerCase()) {
        case undefined:
          icon = "mdi-help-circle";
          url = "";
          image = false;
          break;
        case null:
          icon = "mdi-help-circle";
          url = "";
          image = false;
          break;
        case "":
          icon = "mdi-help-circle";
          url = "";
          image = false;
          break;
        case "google":
          icon = "mdi-google";
          url = "";
          image = false;
          break;
        case "facebook":
          icon = "mdi-facebook";
          url = "";
          image = false;
          break;
        case "instragram":
          icon = "mdi-instragram";
          url = "";
          image = false;
          break;
        case "whatsapp":
          icon = "mdi-whatsapp";
          url = "";
          image = false;
          break;
        case "walkin":
          icon = "mdi-walk";
          url = "";
          image = false;
          break;
        case "llamada":
          icon = "mdi-phone";
          url = "";
          image = false;
          break;
        case "lona / tapial":
          icon = "mdi-vector-rectangle";
          url = "";
          image = false;
          break;
        case "referido":
          icon = "mdi-account-multiple";
          url = "";
          image = false;
          break;
        case "inmuebles24":
          icon = "mdi-sofa";
          url = "";
          image = false;
          break;
        case "revista":
          icon = "mdi-book-open-page-variant-outline";
          url = "";
          image = false;
          break;
        case "eventos":
          icon = "glass-wine";
          url = "";
          image = false;
          break;
        case "caseta":
          icon = "mdi-store-outline";
          url = "";
          image = false;
          break;
        case "vivaanuncios":
          icon = "mdi-vimeo";
          url = "";
          image = false;
          break;
        case "lamudi":
          icon = "mdi-home";
          url = "";
          image = false;
          break;
        case "broker externo":
          icon = "mdi-comment-multiple";
          url = "";
          image = false;
          break;
        case "youtube":
          icon = "mdi-chart-youtube";
          url = "";
          image = false;
          break;
        case "crm":
          icon = "mdi-chart-bar";
          url = "";
          image = false;
          break;
        case "linkedin":
          icon = "mdi-linkedin";
          url = "";
          image = false;
          break;
        case "mercadolibre":
          icon = "mdi-charity";
          url = "";
          image = false;
          break;
        case "easybroker":
          icon = "mdi-charity";
          url = "https://www.easybroker.com/brand_files/icon.png";
          image = true;
          break;
        case "prospección":
          icon = "mdi-account-supervisor-circle-outline";
          url = "";
          image = false;
          break;
        case "otro":
          icon = "mdi-help-circle";
          url = "";
          image = false;
          break;
        default:
          icon = "mdi-information-outline";
          url = "";
          image = false;
          break;
      }
      return {
        isImage: image,
        icon: icon,
        url: url
      };
    },
    formatPhone(phone) {
      if (phone) {
        return phone.includes("+")
          ? `+${phone.replace(/\D/g, "")}`
          : phone.replace(/\D/g, "");
      } else {
        return "";
      }
    },
    async sendUpdateOneField(field, value) {
      if (field === "tracking_phase" || field === "operation_phase") {
        this.innerLoading = field;
        setTimeout(() => {
          let updateData = {
            leadId: this.lead._id,
            contactLeadId: this.lead.contact_lead_id,
            lead: {}
          };
          updateData.lead["phase"] =
            field === "tracking_phase" ? "active" : "in-operation";
          updateData.lead[field] = value;
          this.sendUpdateLead(updateData);
        }, 250);
      } else if (field === "currency") {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          page: this.page,
          currency: value
        });
        this.innerLoading = field;
        setTimeout(() => {
          let updateData = {
            leadId: this.lead._id,
            contactLeadId: this.lead.contact_lead_id,
            lead: {}
          };
          updateData.lead[field] = value;
          this.sendUpdateLead(updateData);
        }, 250);
      } else if (field === "observations_detail") {
        this.innerLoading = field;
        setTimeout(() => {
          let updateData = {
            leadId: this.lead._id,
            contactLeadId: this.lead.contact_lead_id,
            lead: {}
          };
          updateData.lead[field] = {
            message: value,
            category:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail.category
                : "",
            interested_unit:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail.interested_unit
                : "",
            contact_link:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail?.contact_link
                : "",
            link:
              this.lead && this.lead.observations_detail
                ? this.lead.observations_detail?.link
                : ""
          };
          this.sendUpdateLead(updateData);
        }, 250);
      } else if (field === "budget") {
        const cleanBudget = value.trim() === "" ? 0 : value;

        const sameValue = this.inputsWithSameValue(field, cleanBudget);
        if (sameValue) return;
        this.innerLoading = field;

        let updateData = {
          leadId: this.lead._id,
          contactLeadId: this.lead.contact_lead_id,
          lead: {}
        };

        updateData.lead[field] = cleanBudget;

        this.sendUpdateLead(updateData);
      } else if (field === "profile") {
        this.innerLoading = field;
        setTimeout(() => {
          let updateData = {
            leadId: this.lead._id,
            contactLeadId: this.lead.contact_lead_id,
            lead: {}
          };
          this.leadProfile = value;
          updateData.lead[field] = value;
          this.sendUpdateLead(updateData);
        }, 250);
      } else {
        //Validamos si los inputs que estan mandando son del mismo valor
        if (!this.inputsWithSameValue(field, value)) {
          this.innerLoading = field;

          let updateData = {
            leadId: this.lead._id,
            contactLeadId: this.lead.contact_lead_id,
            lead: {}
          };
          updateData.lead[field] = value;
          this.sendUpdateLead(updateData);
        }
      }
    },
    inputsWithSameValue(field, value) {
      if (
        field === "name" ||
        field === "contact_lead_email" ||
        field === "contact_lead_phone" ||
        field === "contact_lead_location" ||
        field === "budget" ||
        field === "contact"
      ) {
        if (field === "budget" && this.leadSecondary.budget === value)
          return true;

        if (this.leadSecondary.contact_lead_email === value) {
          return true;
        } else if (this.leadSecondary.contact_lead_phone === value) {
          return true;
        } else if (this.leadSecondary.contact_lead_name === value) {
          return true;
        } else if (this.leadSecondary.contact_lead_location === value) {
          return true;
        } else if (this.leadSecondary.contact?.medium._id === value.medium) {
          return true;
        } else if (this.leadSecondary.contact?.source._id === value.source) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    sendNotificationAssignBroker(contactBrokerId, nameAssignBroker) {
      this.innerLoading = contactBrokerId;
      setTimeout(() => {
        let updateData = {
          leadId: this.lead._id,
          contactLeadId: this.lead.contact_lead_id,
          zones: this.lead.zones,
          lead: {
            contactBrokerId,
            nameAssignBroker,
            assinedPhase: this.lead.tracking_phase
          }
        };
        this.sendUpdateLead(updateData);
      }, 250);
    },
    sendUpdateLead(updateData) {
      let self = this;
      self
        .fetchUpdatelLead(updateData)
        .catch(() => {
          self.$snotify.error(
            "Error!!!",
            `Ocurrio un problema actualizar el lead.`,
            {
              closeOnClick: true,
              timeout: 4000
            }
          );
        })
        .finally(async () => {
          self.setLoading(false);
          setTimeout(() => {
            self.innerLoading = false;
          }, 1000);
          const data2 = await this.fetchActualLead(this.lead._id);
          // Se hace este parseo para que cuando mute el lead, no cambie el secundario
          let data = JSON.parse(JSON.stringify(data2));
          this.$store.commit("leads/setSecondaryLead", data);
        });
    },
    sendUpdateLeadPhase(value) {
      if (value) {
        const phaseLead = value.split("_");
        const valuePhase = phaseLead[0];
        const phase = phaseLead[phaseLead.length - 1];
        if (phase === "tracking") {
          this.sendUpdateOneField("tracking_phase", valuePhase);
        } else {
          this.sendUpdateOneField("operation_phase", valuePhase);
        }
      }
    },
    /* modal actions */
    // muestra u oculta el modal
    setDialog(show) {
      this.$emit("setDialog", show);
    },
    getContactBrokerOption(option) {
      if (option) {
        this.$store.commit("leads/updateActualLead", {
          leadId: this.lead._id,
          internal_broker: option
        });
        this.sendNotificationAssignBroker(option._id, option.name);
      }
    },
    toggleEndPhase(index, value, event) {
      if (index === "finished" && event === true) {
        this.sendUpdateOneField("phase", index);
      } else {
        this.sendUpdateOneField("phase", "active");
      }
    },
    async verLeadDuplicados() {
      if (this.role !== "admin") return;
      await this.fetchLeadDuplicados(this.lead._id);
      this.modalDuplicados = true;
    },
    getSourceId(source) {
      if (typeof source === "object") {
        return source._id;
      } else {
        return source;
      }
    },
    async updateLead(data) {
      const res = await this.updateLeadV2({
        leadId: this.lead._id,
        dataLead: data
      });
      if (res.Message)
        return this.$snotify.error(
          `Ocurrio un problema actualizar el lead.`,
          "Error!!!"
        );
      return this.$snotify.success(
        `Se actualizo el lead correctamente.`,
        "Exito!!!"
      );
    },
    updateLanguage(language) {
      const data = { language };
      this.updateLead({ contact: data });
    }
  }
};
</script>
<style>
.caption-font {
  font-size: 12px;
  text-transform: none;
}
.loading-layer {
  min-height: 300px;
}
.v-menu__content.menuable__content__active {
  background-color: #f5f5f5;
  padding: 14px 0;
}
.v-slider__thumb-label.primary {
  background-color: transparent !important;
}
.v-toolbar.lead-phase-active {
  background-color: #424242;
}
.v-toolbar.lead-phase-active.lead-tracking-phase-unassigned {
  background-color: #671717;
}
.v-toolbar.lead-phase-in-operation {
  background-color: #172367;
}
.v-toolbar.lead-phase-finished {
  background-color: #fffbe8;
  color: rgb(104, 100, 100) !important;
}
.v-toolbar.lead-phase-discarded {
  background-color: #757575;
}
.v-text-field {
  padding-top: 0px !important;
}
.custom-placeholer-color input::placeholder {
  color: black !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: black;
  opacity: 1;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
.text-info-duplicado {
  color: white !important;
}
</style>
